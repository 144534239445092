<template>
  <button class="btn-default" @click="onClick">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "ButtonDefault",
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.btn-default {
  width: auto;
  position: relative;
  display: flex;
  background-color: #008fd1;
  color: #fff;
  border: 1px solid;
  border-radius: 5px;
  padding: 4px 4px 4px 4px;
  cursor: pointer;
}
</style>
