<template>
  <div :class="size + type + classSelected + 'box-item'" @click="action(data)">
    <div v-if="type == 'backdrop '" class="backdrop__image-container">
      <img
        :src="
          image
            ? 'data:image/png;base64,' + image
            : require('@/assets/campanha-placeholder.png')
        "
        alt=""
      />
    </div>
    <Badge v-if="isPromo" label="Promo" />
    <p class="text">
      {{ data.name }}
      <CheckIcon iconColor="#FFFFFF" />
    </p>
  </div>
</template>

<script>
import Badge from "@/components/Badge/Badge.vue";
import CheckIcon from "@/assets/icons/CheckIcon.vue";

export default {
  name: "Box",
  components: {
    Badge,
    CheckIcon,
  },
  props: {
    data: { type: Object, default: () => {} },
    isSelected: { type: [Boolean, Object], default: () => {} },
    size: { type: String, default: "" },
    type: { type: String, default: "" },
    isPromo: { type: Boolean, default: false },
    image: { type: String, default: "" },
  },
  data() {
    return {
      classSelected:
        this.isSelected == undefined || this.isSelected == false
          ? " "
          : "selected ",
    };
  },
  methods: {
    action(data) {
      this.$emit("action", data);
    },
  },
  watch: {
    isSelected() {
      this.classSelected =
        this.isSelected == undefined || this.isSelected == false
          ? " "
          : "selected ";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Box";
</style>
