<template>
  <div class="overlay">
    <div class="popup popup--extrasmall">
      <div class="popup__message">
        <div class="popup__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-exclamation"
            viewBox="0 0 16 16"
          >
            <path
              d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0L7.1 4.995z"
            />
          </svg>
        </div>
        <div>
          <p class="text-white-highlight">{{ text }}</p>
          <p class="text-white">{{ question }}</p>
        </div>
      </div>
      <div class="row flex-wrap">
        <Button
          :label="firstBtn"
          @action="action('closeWarningPopup')"
          class="btn-secondary--bw btn-small"
        />
        <Button
          :label="secondBtn"
          @action="action('next')"
          class="btn-primary--bw btn-small"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";

export default {
  name: "PopUpWarning",
  props: {
    text: { type: String },
    question: { type: String },
    firstBtn: { type: String },
    secondBtn: { type: String },
  },
  components: {
    Button,
  },
  methods: {
    action(event) {
      this.$emit("action", event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/PopUp";
</style>
