var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overlay"},[_c('div',{class:_vm.data.id == 8 ? 'popup popup--small' : 'popup'},[_c('div',{staticClass:"popup-container"},[_c('div',{staticClass:"popup-text"},[(_vm.data.name)?_c('h2',{staticClass:"xl-title-boldest"},[_vm._v(_vm._s(_vm.data.name))]):_vm._e(),(_vm.data.description)?_c('p',{staticClass:"sm-text-white"},[_vm._v(" "+_vm._s(_vm.data.description)+" ")]):_vm._e()]),_c('div',{staticClass:"list-container mb-2"},_vm._l((_vm.groupedServices.filter(
            (el) => el.length > 0
          )),function(group,index){return _c('Card',{key:index},[_vm._l((group),function(service,index){return _c('CheckboxContainer',{key:index,attrs:{"checkboxClass":"checkbox-round radio-checkbox ","containerClass":"left","inputType":"checkbox","id":'service-' + service.id,"value":service.name,"name":'group-' + service.groupID,"isChecked":_vm.allServicesSelectedIds.includes(String(service.id))},on:{"updateInput":function($event){return _vm.updateInput($event)}}},[_c('LabelComplex',{attrs:{"forLabel":'service-' + service.id}},[_c('div',{staticClass:"row"},[(_vm.servicesWithPromo.includes(String(service.id)))?_c('Badge',{attrs:{"label":"Promo","position":"relative "}}):_vm._e(),_c('div',[_c('p',{staticClass:"text"},[_vm._v(_vm._s(service.name))]),(service.description)?_c('p',{staticClass:"xs-text-italic"},[_vm._v(" "+_vm._s(service.description)+" ")]):_vm._e()])],1),(
                  _vm.servicesWithPromo.includes(String(service.id)) &&
                  service.price_Desc != null
                )?_c('div',{staticClass:"price-container"},[_c('p',{staticClass:"text old-price"},[_vm._v(" "+_vm._s(_vm.formatPrice(service.price, 2, "€"))+" ")]),_c('p',{staticClass:"text price"},[_vm._v(" "+_vm._s(_vm.formatPrice(service.price_Desc, 2, "€"))+" ")])]):_c('div',{staticClass:"price-container"},[_c('p',{staticClass:"text price"},[_vm._v(" "+_vm._s(_vm.formatPrice(service.price, 2, "€"))+" ")])])])],1)}),(false &&
              _vm.data.id == 15 &&
              index ===
                _vm.groupedServices.filter((el) => el.length > 0).length - 1
            )?_c('div',{staticClass:"more-options"},[_c('ButtonDefault',{on:{"click":function($event){_vm.showMoreOptions = !_vm.showMoreOptions}}},[_vm._v("Mais opções")]),(_vm.showMoreOptions)?_c('div',_vm._l((_vm.additionalOptions),function(option,index){return _c('CheckboxContainer',{key:index,attrs:{"checkboxClass":"checkbox-round radio-checkbox ","containerClass":"left","inputType":"checkbox","id":'option-' + option.id,"value":option.name,"name":'option-group-' + option.groupID,"isChecked":_vm.allServicesSelectedIds.includes(String(option.id))},on:{"updateInput":function($event){return _vm.updateInput($event)}}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1):_vm._e()],1):_vm._e()],2)}),1),(_vm.data.id == -1)?_c('p',{staticClass:"xs-text-italic-white align-text-italic-mobile"},[_vm._v(" "+_vm._s(_vm.translateString("maintencanceDisclaimer"))+" ")]):_vm._e(),_c('Button',{staticClass:"btn-primary align-btn-mobile",attrs:{"label":_vm.translateString('confirmar')},on:{"action":_vm.next}})],1),_c('CloseIcon',{attrs:{"isButton":true},on:{"action":_vm.close}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }