<template>
  <div class="box-container row">
    <Box
      v-if="hasCampaigns"
      :data="{
        name: campaignInfo.name
          ? campaignInfo.name
          : translateString('campaign'),
      }"
      type="backdrop "
      size="full "
      :image="campaignInfo.image"
    />
    <Box
      v-for="service in data"
      :key="service.id"
      :data="service"
      :size="service.id == -1 ? 'full ' : ''"
      :isSelected="service.selected|| groupServicesSelected.includes(service.id)"
      :isPromo="groupsWithPromo.includes(String(service.id))"
      @action="action"
    />
  </div>
</template>

<script>
import Box from "@/components/Box/Box.vue";

import { translateString } from "@/functions/functions.js";

export default {
  name: "BoxContainer",
  components: {
    Box,
  },
  props: {
    data: { type: Array },
    groupServicesSelected: { type: Array, default: () => [] },
    groupsWithPromo: { type: Array, default: () => [] },

    campaignInfo: { type: [Array, Object], default: () => [] },
    hasCampaigns: { type: Boolean, default: false },
  },
  methods: {
    translateString,
    action(data) {
      this.$emit("action", data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Box";
</style>
